<template>
  <div
    :class="{
      collapsable: true,
      opened
    }"
  >
    <CollapsableCore
      :height-auto="!isOverflow"
      :collapsed-height="collapsedHeight"
      :opened="opened"
      @done="animationDone"
      @changeOverflowStatus="changeOverflowStatus"
    >
      <div ref="collapsableEl" class="collapsable-item">
        <slot />
      </div>
    </CollapsableCore>

    <transition name="fade">
      <Btn
        v-if="showBtn"
        type="primary"
        size="sm"
        class="overflow-button"
        @click="opened = !opened"
      >
        <SvgIcon icon="arrows" />
      </Btn>
    </transition>
  </div>
</template>

<script>
import Btn from "@/components/ui/Buttons/Button";
import SvgIcon from "@/components/ui/SvgIcon";
import CollapsableCore from "@/components/ui/Collapsable/CollapsableCore";

export default {
  name: "AppCollapsable",
  components: { CollapsableCore, SvgIcon, Btn },
  props: {
    collapsedHeight: { type: Number, default: 620, required: false },
    canOpen: { type: Boolean, default: true },
    initialOpened: { type: Boolean, default: false }
  },
  emits: ["open", "close"],
  data() {
    return {
      opened: this.initialOpened,
      contentHeight: 0,
      resizeHandleTimeout: null,
      showBtn: false
    };
  },

  computed: {
    el() {
      return this.$refs.collapsableEl;
    },

    isOverflow() {
      return this.contentHeight > this.collapsedHeight;
    }
  },

  watch: {
    initialOpened(isOpened) {
      this.opened = isOpened;
    },
    opened(status) {
      this.$emit(status ? "open" : "close");
    }
  },

  methods: {
    changeOverflowStatus(isOverflow) {
      this.showBtn = isOverflow && this.canOpen;
    }
  }
};
</script>

<style scoped lang="scss">
.collapsable {
  position: relative;
  .overflow-button {
    width: max-content;
    max-width: 100%;
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translate(-50%, 50%);
    z-index: 9999;

    .icon {
      margin: 0;
      font-size: 0.8em;
    }
  }

  &.opened {
    .overflow-button {
      transform: translate(-50%, 50%) rotate(180deg);
    }
  }
}
</style>
