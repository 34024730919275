import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createBlock as _createBlock, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle } from "vue"

const _hoisted_1 = {
  ref: "selectedArea",
  class: "d-flex align-items-center selected-wrapper"
}
const _hoisted_2 = { class: "country-selected me-2" }
const _hoisted_3 = { class: "phone-codes" }
const _hoisted_4 = ["data-active", "onClick"]
const _hoisted_5 = { class: "row" }
const _hoisted_6 = { class: "col-8 d-flex align-items-center" }
const _hoisted_7 = { class: "country-code ms-2" }
const _hoisted_8 = {
  key: 0,
  class: "col-4 d-flex justify-content-end"
}
const _hoisted_9 = { class: "ms-2" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_CountryFlag = _resolveComponent("CountryFlag")!
  const _component_svg_icon = _resolveComponent("svg-icon")!
  const _component_TooltipDropdown = _resolveComponent("TooltipDropdown")!
  const _component_AppInput = _resolveComponent("AppInput")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["phone-input", { readonly: _ctx.readonly }]),
    style: _normalizeStyle(`--select-width: ${_ctx.selectedAreaWidth}px`)
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createVNode(_component_CountryFlag, { "iso-code": _ctx.activeCountryIso }, null, 8, ["iso-code"])
      ]),
      (!_ctx.readonly)
        ? (_openBlock(), _createBlock(_component_TooltipDropdown, {
            key: 0,
            strategy: "absolute",
            disabled: _ctx.disabled,
            "max-height": "200px"
          }, {
            content: _withCtx(() => [
              _createElementVNode("ul", _hoisted_3, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.codesList, ({ code, iso }) => {
                  return (_openBlock(), _createElementBlock("li", {
                    key: code,
                    "data-active": iso === _ctx.activeCountryIso,
                    "data-tooltip-option": "",
                    onClick: ($event: any) => (_ctx.selectCountryCode(iso))
                  }, [
                    _createElementVNode("div", _hoisted_5, [
                      _createElementVNode("div", _hoisted_6, [
                        _createVNode(_component_CountryFlag, { "iso-code": iso }, null, 8, ["iso-code"]),
                        _createElementVNode("span", _hoisted_7, _toDisplayString(code), 1)
                      ]),
                      (iso === _ctx.activeCountryIso)
                        ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
                            _createVNode(_component_svg_icon, {
                              icon: "checkmark",
                              class: "checkmark"
                            })
                          ]))
                        : _createCommentVNode("", true)
                    ])
                  ], 8, _hoisted_4))
                }), 128))
              ])
            ]),
            _: 1
          }, 8, ["disabled"]))
        : _createCommentVNode("", true),
      _createElementVNode("span", _hoisted_9, _toDisplayString(_ctx.activeCode), 1)
    ], 512),
    _createVNode(_component_AppInput, {
      id: _ctx.id,
      modelValue: _ctx.phone,
      "onUpdate:modelValue": [
        _cache[0] || (_cache[0] = ($event: any) => ((_ctx.phone) = $event)),
        _ctx.changeHandler
      ],
      "static-focused": true,
      status: _ctx.status,
      readonly: _ctx.readonly,
      required: _ctx.required,
      label: _ctx.label,
      type: "number",
      "input-type": "material",
      class: "phone-field",
      "data-active-country-iso": _ctx.activeCountryIso
    }, null, 8, ["id", "modelValue", "status", "readonly", "required", "label", "data-active-country-iso", "onUpdate:modelValue"])
  ], 6))
}