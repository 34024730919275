<template>
  <div class="app-flag-icon">
    <CountryFlag :country="isoCode" :rounded="true" size="normal" />
  </div>
</template>

<script>
import CountryFlag from "vue-country-flag-next";
export default {
  components: { CountryFlag },
  props: { isoCode: { type: String, required: true } },
  computed: {
    uppercaseCode() {
      return this.isoCode.toUpperCase();
    }
  }
};
</script>

<style lang="scss">
.app-flag-icon {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  overflow: hidden;
  .flag {
    margin-top: -10.5px;
    margin-left: -16px;
    transform: scale(0.55);
  }
}
</style>
